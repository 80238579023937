<template>
  <div>
    <div >
      <!-- <span class="">一级：</span> -->
      <!-- <ul class="tbox">
        <li style="margin-top: 10px display:flex">
            <div class="cengji">一级:</div>
            <div>
              <a href="javascript:;" v-for="(option,index) in dahang" :key="option.index" v-bind:class="{active:currentIndex===index}"
                  @click="listBundle(index,option)">{{option.dempFirst}}
              </a>
            </div>
        </li>
        <li style="margin-bottom: 20px; display:flex">
            <div class="cengji" style="margin-top: 30px">二级:</div>
            <div style="margin-top: 30px">
              <a href="javascript:;" v-for="(option,i) in  dahang[currentIndex].dempSecond" :key="'info2-'+i" v-bind:class="{active:ercurrentIndex===i}"
                  @click="erlistBundle(i,option)">{{option}}
              </a>
            </div>
        </li>
      </ul> -->
      <div class="tbox">
          <el-row :gutter="24" style="margin: 20px 0">
            <el-col :span="4" class="cengji">一级:</el-col>
            <el-col :span="20" class="left">
              <a href="javascript:;" v-for="(option,index) in dahang" :key="option.index" v-bind:class="{active:(currentIndex===index)}"
                      @click="listBundle(index,option)">{{option.dempFirst}}
              </a>
            </el-col>
          </el-row>
          <el-row :gutter="24" style="margin: 20px 0">
            <el-col :span="4" class="cengji">二级:</el-col>
            <el-col :span="20" class="left">
              <a href="javascript:;" v-for="(option,i) in  (dahang[currentIndex].dempSecond ? dahang[currentIndex].dempSecond : '')" :key="'info2-'+i" v-bind:class="{active:ercurrentIndex===i}"
                    @click="erlistBundle(i,option)" style="margin-bottom:10px">{{option}}
              </a>
            </el-col>
          </el-row>
      </div>
    </div>
    <div class="mainbox">
      <span class="zong">共<span style="color: red">{{total}}</span>个疾病</span>
      <dl class="textList">
        <dt v-for="(item,index) in nowData" :key="'info13-'+index">
            <a @click="itemclick(option.tagId)" v-for="(option,i) in item.list" :key="'info3-'+i" :title="option.illness">
              <i></i>
              {{option.illness}}
            </a>
        </dt>
      </dl>
    </div>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="160"
        :current-page="currentPage"
        layout="prev, pager, next, jumper"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
import { getDepartment } from 'network/getData'
export default {
  name: 'Disease',
  data () {
    return {
      currentIndex: 0,
      ercurrentIndex: 0,
      total: 0,
      currentPage: 1,
      dempFirst: '内科',
      dempSecond: '心血管内科',
      dahang: [
        {
          dempSecond: []
        }
      ],
      listData: [
        {
          title: '一级',
          list: ['内科', '外科', '儿科', '男科', '皮肤科', '肿瘤科', '内科']
        }
      ],
      nowData: [],
      Data: []
    }
  },
  // metaInfo () {
  //   return {
  //     meta: [
  //       { name: 'keywords', content: '科普视频,专家文章,专家音频,名医问答,查疾病,瑞尔安心、瑞尔良医、瑞尔良医app、瑞尔安心官网、瑞尔视景、瑞尔视景科技有限公司、单道动态心电记录仪、7天可穿戴心电监测设备、智能心电监测设备' },
  //       { name: 'description', content: '瑞尔安心在线平台,专家,疾病,科室等详细信息,由专家生产的科普视频,文章,音频等内容,瑞尔安心平台向全民提供“科普视频+语音问答+图文咨询+文章+大数据分析”多位一体的精准健康科普知识，满足大众多样化、多场景应用的健康科普需求。致力于通过互联网技术推动医生共享、知识共享、服务共享、经济共享，放大医生价值和健康科普内容价值，用通俗易懂的方式以及直白语言，制作出大众听得懂、记得住、学得会、用得上的健康科普知识，切实提升公众健康素养，让更多人能够健康地享受美好生活，助力全民健康。研发出多款医疗器械，实现了心脑血管病患者、高危人群得日常疾病筛查和健康管理，并配套了全程专业的健康管理服务，在国内医疗产品行业中具有很高的地位和影响。公司主要从事公司下设医学研发中心、软件开发中心、生产制造中心、营销中心、医学服务中心。' }
  //     ]
  //   }
  // },
  async mounted () {
    await this.getdhdata()
    // console.log(this.$route, '$router.path')
    if (this.$route.query.dempFirst) {
      await this.getDepartmentdata(160, 1, null, this.$route.query.dempSecond ? this.$route.query.dempSecond : this.dempSecond, 1)
      this.currentIndex = Number(this.$route.query.index)
      this.ercurrentIndex = Number(this.$route.query.eindex)
      // // console.log(this.currentIndex, this.ercurrentIndex)
    }
    this.getDepartmentdata(160, 1, null, '心血管内科', 1)
  },
  methods: {
    // getdata (rows, page, dempFirst, dempSecond, isIndex) {
    //   this.getDepartmentdata(rows, page, dempFirst, dempSecond, isIndex).then((res) => {
    //     // this.nowData = res.data.data[0].illnessList
    //     const Data = res.data.data[0].illnessList
    //     this.total = res.data.data[0].titleNum
    //     const arr = []
    //     for (let i = 0; i < Data.length; i++) {
    //       const obj = {}
    //       if (i === 0) {
    //         obj.id = i
    //         obj.list = Data.slice(0, 30)
    //         arr.push(obj)
    //       }
    //       if ((i + 1) % 30 === 0) {
    //         obj.id = i
    //         obj.list = Data.slice(i, i + 30)
    //         arr.push(obj)
    //       }
    //     }
    //     this.nowData = arr
    //   })
    // },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      this.currentPage = val
      // console.log(`当前页: ${val}`)
      this.getDepartmentdata(160, this.currentPage, this.dempFirst, this.dempSecond, 1)
    },
    listBundle (index, option) {
      this.getDepartmentdata(160, 1, null, option.dempSecond[0], 1)
      this.currentIndex = index
      this.currentPage = 1
      this.ercurrentIndex = 0
      this.dempFirst = option.dempFirst
      this.dempSecond = option.dempSecond[0]
    },
    erlistBundle (i, option) {
      this.ercurrentIndex = i
      this.dempSecond = option
      this.currentPage = 1
      // console.log(this.dempFirst, 'option')
      this.getDepartmentdata(160, 1, null, option, 1)
    },
    itemclick (e) {
      this.$router.push({ path: '/disease/diseasedetail', query: { i: e } })
    },
    // 通过请求获取数据
    async getDepartmentdata (rows, page, dempFirst, dempSecond, isIndex) {
      try {
        const res = await getDepartment(rows, page, dempFirst, dempSecond, isIndex)
        const Data = res.data.data[0].illnessList
        this.total = res.data.data[0].titleNum
        const arr = []
        for (let i = 0; i < Data.length; i++) {
          const obj = {}
          if (i === 0) {
            obj.id = i
            obj.list = Data.slice(0, 30)
            arr.push(obj)
          }
          if ((i + 1) % 30 === 0) {
            obj.id = i
            obj.list = Data.slice(i, i + 30)
            arr.push(obj)
          }
        }
        this.nowData = arr
      } catch (error) {
        // console.log(error)
      }
    },
    async getdhdata () {
      try {
        const result = await getDepartment()
        this.dahang = await result.data.data.demp
      } catch (error) {
        // console.log(error)
      }
    }
  }
}
</script>

<style scoped>
.tbox{
  /* position: absolute; */
  /* height: 124px; */
  /* margin-left: 120px; */
  /* text-align: center; */
    margin: 0 auto;
  width: 1200px;
  top: 100px;
  background: #FBFBFB;
  border: 1px solid #F6F6F6;
  box-sizing: border-box;
  border-radius: 0px;
}
.tbox span{
  /* position: absolute; */
  width: 48px;
  height: 24px;
  left: calc(50% - 48px/2 - 542.5px);
  top: 21px;
  /* font-family: Microsoft YaHei; */
  font-size: 15px;
  line-height: 24px;
  /* text-align: center; */
  color: #999999;
}
a {
    display: inline-block;
    margin: 0px 20px 0 0;
    text-decoration: none;
    color: #262626;
}
li {
    list-style: none;
    /* float: left; */
    /* margin: 10px 20px 0 0; */
    width: 90%;
    /* height: 70px; */
    margin-left: 50px;
    margin-top: -3px;
    /* border-bottom: 1px dashed #ccc; */
}
.active{
  padding: 0 5px;
  border-radius: 4px;
  border: 1px solid #F6A609;
  color: #FFFFFF;
  background-color: #F6A609;
}
.mainbox{
  margin-left: 120px;
  width: 1200px;
  margin-top: 20px;
  margin: 0 auto;
}
.zong{
  /* position: absolute; */
  float: right;
  height: 31px;
  /* left: 0%;
  right: 0%;
  top: 0px; */
  border-bottom: 1px solid #eee;
  margin-top: 20px;
}
.textList {
  width: 1200px;
  overflow: hidden;
}
dl {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.textList dt, .textList dd {
  width: 1200px;
  padding: 18px 0;
}
.textList dt {
  height: 222px;
  border-bottom: 1px dashed #eee;
}
.textList a {
    float: left;
    position: relative;
    height: 40px;
    padding: 8px 0 0 18px;
    line-height: 20px;
    width: 112px;
    margin-right: 18px;
    font-size: 14px;
    color: #8C8C8C;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-align: left;
}
.textList a i {
    /* position: absolute; */
    top: 18px;
    left: 5px;
    width: 4px;
    height: 2px;
    background: #666;
}
a{
  cursor: pointer;
}
.textList a:hover{
  color: #262626;
}
.cengji{
  text-align: right;
}
.el-pagination{
  /* margin: 0 auto; */
  text-align: center;
  margin-bottom: 50px;
}
</style>
